import React from 'react'
import { Segment, Dimmer, Loader as LoaderSemantic } from 'semantic-ui-react'

/**
 * Display loader animation.
 */
const Loader: React.FC = () => {
  return (
    <Segment basic padded='very'>
      <Dimmer active inverted>
        <LoaderSemantic />
      </Dimmer>
    </Segment>
  )
}

export default Loader
