const config = {
  env: process.env.NODE_ENV,
  auth: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
    container: process.env.REACT_APP_AUTH0_CONTAINER,
  },
  pusher: {
    key: process.env.REACT_APP_PUSHER_KEY || '',
    cluster: process.env.REACT_APP_PUSHER_CLUSTER || '',
    channel: {
      notification: 'channel-rt-notification-',
    },
  },
  google: {
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    scriptURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
  },
  baseUrl: process.env.REACT_APP_BASE_URL,
  loginUrl: process.env.REACT_APP_LOGIN_URL,

  tempUserDetail: {
    grantType: process.env.REACT_APP_GRANT_TYPE,
    clientId: process.env.REACT_APP_CLIENT_ID,
    userName: process.env.REACT_APP_USER_NAME,
    password: process.env.REACT_APP_PASSWORD,
    audience: process.env.REACT_APP_AUDIENCE,
  },
}
export default config
