import routes from './constants/routes'
import React, { useContext } from 'react'
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom'
import { AuthContext } from './components/contexts/AuthContext'

interface PublicRouteProps extends RouteProps {
  restricted: boolean
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
}

const PublicRoute: React.FC<PublicRouteProps> = ({ component: Component, restricted, ...rest }) => {
  const { auth } = useContext(AuthContext)

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated && restricted ? (
          <Redirect
            to={{
              pathname: routes.HOME,
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}
export default PublicRoute
