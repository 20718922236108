import React from 'react'
import ReactDOM from 'react-dom'

import { BrowserRouter } from 'react-router-dom'

// import './assets/css/index.css'
import './assets/scss/global.scss'
import 'semantic-ui-css/semantic.min.css'

import App from './App'
import * as serviceWorker from './serviceWorker'
import Auth0ProviderWithHistory from 'auth/auth0-provider-with-history'

ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <App />
    </Auth0ProviderWithHistory>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
