import AuthState from '../resources/domain/state/AuthState'
import { AuthActions, SET_STATE, SET_USER_INFO, RESET_AUTH } from '../actions/auth'

export const initialState: AuthState = {
  isAuthenticated: false,
  user: {
    email: null,
    nickname: '',
    gender: null,
    avatar: '',
    name: '',
    hasEmailVerified: false,
    updatedAt: '',
  },
}

export const reducer = (state: AuthState = initialState, action: AuthActions): AuthState => {
  switch (action.type) {
    case SET_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case SET_USER_INFO:
      return {
        ...state,
        user: action.payload,
      }

    case RESET_AUTH:
      return initialState

    default:
      return state
  }
}
