import Auth0Lock from 'auth0-lock'
import * as auth0 from 'auth0-js'

import config from '../config/config'
import image from '../assets/images/roundtableLogo.png'

export type Lock = typeof Auth0Lock

export function getAuth(): Lock {
  return new Auth0Lock(config.auth.clientId, config.auth.domain, {
    auth: {
      redirect: false,
      responseType: 'token',
      sso: false,
      audience: config.auth.audience,
    },
    container: config.auth.container,
    theme: {
      logo: image,
      primaryColor: '#3b5998',
    },
    allowShowPassword: true,
    allowAutocomplete: true,
    languageDictionary: {
      emailInputPlaceholder: 'something@youremail.com',
      title: 'Login to Round Table',
    },
  })
}

export function getWebAuth() {
  return new auth0.WebAuth({
    domain: config.auth.domain,
    clientID: config.auth.clientId,
  })
}
