import React, { useEffect } from 'react'
import Pusher from 'pusher-js'
import { IntlProvider } from 'react-intl'
import { HelmetProvider } from 'react-helmet-async'
import { useAuth0, withAuth0 } from '@auth0/auth0-react'

import { setAccessToken } from 'helper/storage'

import Routes from './Router'
import config from './config/config'
import locale from './components/locale'
import Loader from './components/utilities/Loader'
import useScript from 'components/hooks/useScript'
import { UIProvider } from './components/contexts/UIContext'
import { PusherProvider } from './components/contexts/PusherContext'

// Enable pusher logging - don't include this in production
Pusher.logToConsole = config.env === 'development'

const pusher = new Pusher(config.pusher.key, {
  cluster: config.pusher.cluster,
  forceTLS: true,
})

const App = () => {
  useScript(config.google.scriptURL)

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()
  useEffect(() => {
    async function setAccessTokenInStorage() {
      if (!isLoading && isAuthenticated) {
        const token = await getAccessTokenSilently({
          audience: 'https://v2.roundtabletrading.com',
        })
        setAccessToken(token)
      }
    }
    setAccessTokenInStorage()
  }, [isLoading, isAuthenticated, getAccessTokenSilently])
  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    )
  }

  return (
    <IntlProvider messages={locale.messages[locale.lang]} locale={locale.lang} defaultLocale='en'>
      <UIProvider>
        <PusherProvider pusher={pusher}>
          <HelmetProvider>
            <Routes />
          </HelmetProvider>
        </PusherProvider>
      </UIProvider>
    </IntlProvider>
  )
}

export default withAuth0(App)
