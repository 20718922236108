import Pusher from 'pusher-js'
import React, { createContext } from 'react'

export interface IUIContext {
  pusher: Pusher | null
}

export const PusherContext = createContext<IUIContext>({
  pusher: null,
})

interface PusherProviderProps {
  pusher: Pusher
}

export const PusherProvider: React.FC<PusherProviderProps> = ({ pusher, children }) => {
  return <PusherContext.Provider value={{ pusher }}>{children}</PusherContext.Provider>
}

export function usePusher(): Pusher {
  const context = React.useContext(PusherContext)

  if (!context || !context.pusher) {
    throw new Error('usePusher must be used within a PusherProvider')
  }

  return context.pusher
}
