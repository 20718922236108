import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import routes from './constants/routes'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import Loader from './components/utilities/Loader'

const Home = React.lazy(() => import('./components/views/Home'))
const Login = React.lazy(() => import('./components/auth/Login'))
const Logout = React.lazy(() => import('./components/auth/Logout'))
const SignUp = React.lazy(() => import('./components/views/SignUp'))
const PageNotFound = React.lazy(() => import('./components/views/PageNotFound'))

const Routes: React.FC = () => (
  <Router>
    <main>
      <Suspense fallback={<Loader />}>
        <Switch>
          <PublicRoute restricted path={routes.LOGIN} component={Login} />
          <PrivateRoute path={routes.LOGOUT} component={Logout} />
          <Route path={routes.SIGN_UP} component={SignUp} />
          <PrivateRoute path={routes.HOME} component={Home} />
          <Route component={PageNotFound} />
        </Switch>
      </Suspense>
    </main>
  </Router>
)

export default Routes
