import React from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import config from 'config/config'
import { AppState } from '@auth0/auth0-react/dist/auth0-provider'

export const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  const history = useHistory()

  const onRedirectCallback = (appState: AppState) => {
    history.push(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={config.auth.domain}
      clientId={config.auth.clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience='https://v2.roundtabletrading.com'
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
