import React, { createContext, useReducer, useEffect, useCallback } from 'react'

import { Lock, getAuth } from 'util/auth'
import { setAccessToken } from 'helper/storage'
import { reducer, initialState } from 'reducers/auth'
import AuthState from 'resources/domain/state/AuthState'
import { isSessionExpired, parseSession } from 'util/session'
import { AuthActions, setAuthState, setUser, resetAuth } from 'actions/auth'

export interface IAuthContext {
  auth: AuthState
  login: () => void
  logout: () => void
  dispatch: React.Dispatch<AuthActions>
}

export const AuthContext = createContext<IAuthContext>({
  auth: initialState,
  login: () => null,
  logout: () => null,
  dispatch: () => null,
})

export const AuthProvider: React.FC = ({ children }) => {
  const [auth, dispatch] = useReducer(reducer, initialState)

  const fetchUser = useCallback((lockObj: Lock, accessToken: string) => {
    lockObj.getUserInfo(accessToken, (error, user) => {
      if (error) {
        return
      }

      const payload = {
        email: user.email || null,
        hasEmailVerified: user.email_verified === true,
        nickname: user.nickname,
        gender: user.gender || null,
        avatar: user.picture,
        name: user.name,
        updatedAt: user.updated_at,
      }

      dispatch(setUser(payload))
    })
  }, [])

  const fetchSession = useCallback(
    (lock: Lock) => {
      lock.checkSession({}, (err, authResult) => {
        if (err || !authResult) {
          return
        }

        const payload = parseSession(authResult)
        setAccessToken(authResult.accessToken)

        if (payload && !isSessionExpired(payload.expiresIn)) {
          fetchUser(lock, payload.accessToken)

          dispatch(setAuthState({ isAuthenticated: true }))
        }
      })
    },
    [fetchUser]
  )

  const login = useCallback(() => {
    const lock = getAuth()

    lock.on('authenticated', (authResult: any) => {
      fetchUser(lock, authResult.accessToken)
      setAccessToken(authResult.accessToken)
      dispatch(setAuthState({ isAuthenticated: true }))
    })

    lock.show()
  }, [fetchUser])

  const logout = useCallback(() => {
    const lock = getAuth()

    dispatch(resetAuth())

    lock.logout({})
  }, [])

  useEffect(() => {
    const lock = getAuth()

    fetchSession(lock)
  }, [fetchSession])

  return <AuthContext.Provider value={{ auth, login, logout, dispatch }}>{children}</AuthContext.Provider>
}
