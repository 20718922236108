const routes = {
  BASE: process.env.PUBLIC_URL,

  LOGIN: '/login',
  LOGOUT: '/logout',
  SIGN_UP: '/sign-up',
  PROFILE: '/profile',

  HOME: '/',
  TASK: '/task',
  WELCOME: '/welcome',
  LOGIN_UI: '/login-ui',
  PRODUCT_DETAIL: '/product-detail',
  NOTIFICATIONS: '/notifications',

  DEALERS: '/dealers',
  ADD_DEALERS: '/dealers/add',
  DEALER: '/dealers/:id',

  GROUPS: '/groups',
  GROUP: '/groups/:id/:slug',
  GROUP_PAGES: '/groups/:id/:slug/:page',
  ADD_GROUPS: '/groups/add',
  DEALER_GROUPS: '/dealer-groups',

  EVENTS: '/events',
  DISCUSSION: '/discussion',

  PRIVATE: '/private',

  ITEMS: '/items',
  ITEM: '/items/:id',
  ITEM_TYPE: '/items/types/:typeId',
}

export default routes
