import messages_en from '../lang/en-US.json'
import messages_fr from '../lang/fr-FR.json'

const messages: { [key: string]: any } = {
  en: messages_en,
  fr: messages_fr,
}

const lang = navigator.language.split(/[-_]/)[0]

export default {
  lang,
  messages,
}
